import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Pages/Panel/Views/AnalyticalDashboard/Dashboard";
 
 
import "./App.css";
import { Responsibilities } from "./Configuration/Enums";
import User_Provider from "./Contexts/User";
import Private_Route from "./Routes/Private_Route";
import WelcomePage from "./Pages/Panel/Views/Welcome/Welcome";
import Lead from "./Pages/Panel/Views/Lead/Lead";

import Authentication from "./Pages/Authentication/Authentication";
import Not_Found from "./Pages/Not_Found/Not_Found";
 
const Application = () =>
	<User_Provider>
		<BrowserRouter>
			<Routes>
				<Route key='Authentication_Page_Key' path="/" element={<Authentication />} />
				<Route key='Welcome_Page_Key' path="/welcome" element={<Private_Route Redirection_Path='/not-found'><WelcomePage /></Private_Route>} />
				 
			 	<Route key='Dashboard_Page_Key' path="/dashboard" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Dashboard}><Home /></Private_Route>} />
			 
				<Route key='Leads_Page_Key' path="/leads" element={<Private_Route Redirection_Path='/not-found' ><Lead /></Private_Route>} />
				
 				<Route key='Miscellaneous_Page_Key' path="*" element={<Not_Found />} />
			</Routes>
		</BrowserRouter>
	</User_Provider>

export default Application;
