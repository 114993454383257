import { useContext, useState, useEffect, ChangeEvent } from "react";
import { toast, ToastContainer } from "react-toastify";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Pagination, Stack, Typography } from "@mui/material";
import ExcelExport from "../../../../Excel/ExcelExport";
import axios from "axios";
import { API_Endpoint } from "../../../../Configuration/Settings";
import Loader from "../../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import SearchBar from "../../../../Components/SearchBox/Search";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";

interface LeadData {
  id: string;
  name: string;
  email: string;
  mobile: number;
  message: string;
  createdOn: string;

}

export default function Lead() {
  const [selectVendor, setSelectVendor] = useState("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [LeadData, setLeadData] = useState<Array<LeadData>>([]);
  const [searchUserId, setSearchUserId] = useState<string>("");
  const [searchUserName, setSearchUserName] = useState<string>("");
  const [searchUserMob, setSearchUserMob] = useState<string>("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const Navigate = useNavigate();
  const { User } = useContext(User_Context);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);



  const handleSearchUserId = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserId(event.target.value);
  };
  const handleSearchUserName = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserName(event.target.value);
  };
  const handleSearchUserMob = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserMob(event.target.value);
  };
  const clearDate = () => {
    setStartDate('')
    setEndDate('')
  }


  const filterUser = () => {
    setLoading(true);
    const filter: { _id?: string, name?: string, mobile?: string } = {};
    if (searchUserId) {
      filter._id = searchUserId
    }
    if (searchUserName) {
      filter.name = searchUserName
    }
    if (searchUserMob) {
      filter.mobile = searchUserMob
    }
    const token = localStorage.getItem("token");
    let data = {
      pageSize: 10,
      pageNumber: currentPage,
      filter: filter
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_Endpoint}/backoffice/reports`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { token: encryptData(data) },
    };

    axios
      .request(config)
      .then((response) => {
        response.data.data = decryptData(response.data.data)
        const pages = response.data.data.payload.count / 10;
        setTotalPages(Math.ceil(pages));
        setLeadData(response.data.data.payload.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  }

  const getData = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let filter = {}
    if (startDate && endDate) {
      filter = { startDate: startDate, endDate: endDate }
    }
    let data = {
      pageSize: Number(itemsPerPage),
      pageNumber: currentPage,
      filter: filter
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_Endpoint}/leads/getAll`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { token: encryptData(data) },
    };

    axios
      .request(config)
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          const appData = decryptData(data);
          setLeadData(appData.payload.items);
          setLoading(false);
        } else {
          setLoading(false);
          toast('Error Occured!');
        }

        // const pages = 1;//response.data.payload.count / 10;
        // setTotalPages(Math.ceil(pages));
      })
      .catch((error) => {

        console.log(error);
        setLoading(false)
      });
  }
  useEffect(() => {
    getData();
  }, [currentPage]);

  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue)
  }
  const handleSelectVendor = (newValue: any) => {
    setSelectVendor(newValue)
  }
  const topCenter = (x: string) => {
    toast.info(x, {
      position: 'top-center'
    });
  };


  const [open, setOpen] = useState(false); // State to control dialog visibility
  const [dialogMessage, setDialogMessage] = useState<string>(""); // State to hold the current message

  const handleOpen = (message: string) => {
    setDialogMessage(message); // Set the message to display
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
    setDialogMessage(""); // Clear the message
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <ToastContainer autoClose={2000} position="top-center" />
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs tab={"Leads"} />
              <div className="container-fluid">
                <div className="row">
                  <div className="row tp-form">
                    <div className="d-flex align-items-center mb-1">
                      <div className="container-fluid">
                        <div className="row tp-form">
                          <div className="col-6 col-xl-2 col-sm-3 pdrt">
                            <label className="lbl">From Date</label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="From Date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>
                          <div className="col-6 col-xl-2 col-sm-3 pdrt">
                            <label className="lbl">To Date</label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="To Date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                          <div className="col-6 col-xl-2 col-sm-3 pdrt">
                            <label className="lbl"></label>
                            <a className="sechBtn mt-1" onClick={getData}>
                              Apply
                            </a>
                          </div>
                          <div className="col-6 col-xl-2 col-sm-3 pdrt">
                            <label className="lbl"></label>
                            <a onClick={clearDate} className="sechBtn mt-1">
                              Clear Dates
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-6 col-xl-1 col-sm-4 search-4">
                        <ExcelExport
                          inputData={LeadData}
                          headings={headings}
                          fileName={"Profit & Loss"}
                        />
                      </div>
                      <div>
                        <span className="ms-1">Download Data</span>
                      </div>*/}

                    </div>

                  </div>
                  <div className="col-12 mt-2">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Dated</th>
                            <th>Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {LeadData.length > 0
                            ? (
                              LeadData.map((lead, index) => (
                                <tr key={lead.id}>
                                  <td></td>
                                  <td >{lead.name} </td>
                                  <td>{lead.email} </td>
                                  <td >{lead.mobile} </td>
                                  <td >{lead.createdOn.slice(0, 10)} </td>
                                  <td >{lead.message.length > 30 ? (
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={() => handleOpen(lead.message)} // Trigger dialog open
                                    >
                                      View
                                    </Button>
                                  ) : (
                                    lead.message
                                  )}</td>



                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  No data available for the selected date range.
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>

                    </div>
                    <ul className="pagination d-flex justify-content-center">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={(event, newPage) => setCurrentPage(newPage)}
                        />
                      </Stack>
                    </ul>
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          @ travelstarholidays.com
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              License
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth // Ensures dialog takes up available width
            maxWidth="sm" // Sets a reasonable max-width
            PaperProps={{
              sx: {
                maxHeight: "90vh", // Limits height to 90% of the viewport
                p: 2, // Adds padding
              },
            }}
          >
            <DialogTitle sx={{ textAlign: "center", fontSize: "1rem", fontWeight: "bold" }}>
              Message
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1" sx={{ wordWrap: "break-word", fontSize: "1rem" }}>
                {dialogMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined" color="primary" sx={{ width: "100%" }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}
